import request from '@/utils/request'


// 查询优惠券列表
export function listCoupon(query) {
  return request({
    url: '/coupon/coupons/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券分页
export function pageCoupon(query) {
  return request({
    url: '/coupon/coupons/page',
    method: 'get',
    params: query
  })
}

// 查询优惠券详细
export function getCoupon(data) {
  return request({
    url: '/coupon/coupons/detail',
    method: 'get',
    params: data
  })
}

// 新增优惠券
export function addCoupon(data) {
  return request({
    url: '/coupon/coupons/add',
    method: 'post',
    data: data
  })
}

// 修改优惠券
export function updateCoupon(data) {
  return request({
    url: '/coupon/coupons/edit',
    method: 'post',
    data: data
  })
}

// 删除优惠券
export function delCoupon(data) {
  return request({
    url: '/coupon/coupons/delete',
    method: 'post',
    data: data
  })
}
