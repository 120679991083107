<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item v-if='this.formType == 1' label="是否批量生成" prop="isSaveBatch" >
        <a-select v-model="form.isSaveBatch" placeholder="请选择" style="width: 60%" allow-clear>
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if='form.isSaveBatch==0' label="优惠券激活码" prop="couponNum" >
        <a-input v-model="form.couponNum" placeholder="请输入优惠券激活码" />
      </a-form-model-item>
      <a-form-model-item label="会员" prop="vipId" >
        <a-select   placeholder="请选择会员" v-model="form.vipId" allow-clear >
          <a-select-option v-for="(item,index) in vipList"  :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item v-if='this.formType == 1 && form.isSaveBatch == 1' label="批量生成数量" prop="saveBatchNum" >
        <a-input-number style='width: 60%' v-model='form.saveBatchNum' placeholder='请输入批量生成数量(最多500)' :min='10' :maxLength='10' :max='500' />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCoupon, addCoupon, updateCoupon } from '@/api/coupon/coupon'
import {  listMenu } from '@/api/vip/menu'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      vipList:[],
      // 表单参数
      form: {
        id: null,

        couponNum: null,
        isSaveBatch:0,
        saveBatchNum:null,
        vipId: null,

        useFlag: null,

        userId: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isSaveBatch: [
          { required: true, message: '优惠券激活码不能为空', trigger: 'blur' }
        ],
        couponNum: [
          { required: true, message: '优惠券激活码不能为空', trigger: 'blur' }
        ],
        vipId: [
          { required: true, message: '会员不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getVipList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getVipList(){
      listMenu({}).then(response => {
        this.vipList = response.data
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        couponNum: null,
        isSaveBatch:0,
        saveBatchNum:null,
        vipId: null,
        useFlag: null,
        userId: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCoupon({"id":id}).then(response => {
        this.form = response.data
        this.form.isSaveBatch = 0
        this.open = true
        this.formTitle = '修改'
      })

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCoupon(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCoupon(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
